import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CurrencyFormat from "react-currency-format";

export default function NumberComp(props) {
  // console.log(typeof props.value);

  let numericValue;
  let decimalScale;

  // Check if props.value is null

  if (
    // Check if the value is in exponential notation
    typeof props.value === "string" &&
    /[eE]/.test(props.value)
  ) {
    var parts = props.value.split("e");
    var nonExponentialPart = parts[0];
    numericValue = nonExponentialPart;
    decimalScale = 2; // Set decimal scale to 2 for exponential notation
  } else if (typeof props.value === "string" && /[a-zA-Z]/.test(props.value)) {
    return props.value; // Display as it is
  } else if (props.value === null) {
    numericValue = 0; // Default value for null
    decimalScale = 2; // Use default decimal scale
  } else if (
    /^\d+\.\d+\s*-\s*\d+\.\d+$/.test(props.value) ||
    /^\d+\s*-\s*\d+$/.test(props.value) ||
    /^<\s*\d+$/.test(props.value) ||
    /^-\d+\s*-\s*\d+$/.test(props.value) ||
    /\d+>\s*$/.test(props.value) ||
    /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(props.value) ||
    /^\d+\.\d+\s*-\s*\d+\s*$/.test(props.value) ||
    /^\d+\s*-\s*\d+\s*$/.test(props.value) ||
    /^\d+\s*\+\s*$/.test(props.value) || // Pattern for "100 +"
    /^\d+\.\d+\+\s*$/.test(props.value) || // Pattern for "10.00+"
    /^\d+\.\d+\s*\+\s*$/.test(props.value) || // Pattern for "11.50 +"
    /^<\s*\d+\.\d+\s*$/.test(props.value) // Pattern for "< 4.75"
  ) {
    // Check if the value matches the pattern "6.75 - 6.99" or "1000000 - 1999999"
    return props.value; // Display as it is
  } else if (typeof props.value === "string" && props.value.endsWith("%")) {
    numericValue = parseFloat(props.value); // Convert to a numeric value and remove the "%"
    decimalScale = 3; // 3 decimal places for percentages
  } else {
    numericValue = props.value; // Treat as a number
    decimalScale = 2; // 2 decimal places for numbers
  }

  return (
    <React.Fragment>
      <Tooltip title={props.value} aria-label="add">
        <React.Fragment>
          {/* {moment(props.date, "YYYYMMDD").format('MM/DD/YYYY')} */}
          <CurrencyFormat
            value={numericValue}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            suffix={
              props.value !== null &&
              typeof props.value === "string" &&
              props.value.endsWith("%")
                ? "%"
                : ""
            }
            renderText={(value) => <div>{value}</div>}
          />
        </React.Fragment>
      </Tooltip>
    </React.Fragment>
  );
}
