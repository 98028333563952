/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import "react-input-range/lib/css/index.css";
// import Spreadsheet from "react-spreadsheet";
// import { Category } from "@material-ui/icons";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import {
  dealPreviewMapFieldsRecurring,
  CalculatePrePayments,
  CalculatePayments,
  CalculateConsolidatedPayments,
  DownloadExcel,
} from "../../../../../servies/services";
import SearchIcon from "@material-ui/icons/Search";
import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

// import {
// MuiPickersUtilsProvider,
// KeyboardTimePicker,
// KeyboardDatePicker,
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
class LoanTapes_IssuerRecurring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullScreen: false,
      tableData: [],
      DealName:
        sessionStorage.getItem("dealname") === null
          ? sessionStorage.getItem("servicerdealname")
          : sessionStorage.getItem("dealname"),
      ServicerName:
        sessionStorage.getItem("Servicer") === null
          ? sessionStorage.getItem("servicerdashboardname")
          : sessionStorage.getItem("Servicer"),
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getDashboardDates:JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "",
      sortAscending: true,
     
    };
  }

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  showPrev = () => {
    window.location.assign("/issuer/collateral_IssuerRecurring");
  };
  showNext = () => {
    window.location.assign("/issuer/file_IssuerRecurring");
  };

  handleSort = (column) => {
    const { sortColumn, sortAscending, tableData } = this.state;

    // Determine the new sort order
    let newSortAscending;
    if (sortColumn === column) {
      newSortAscending = !sortAscending;
    } else {
      // Default to ascending order when sorting a new column
      newSortAscending = true;
    }

    // Custom compare function for sorting
    const compareFunc = (a, b) => {
      const valueA = a[column] ? a[column].toLowerCase() : "";
      const valueB = b[column] ? b[column].toLowerCase() : "";
      if (valueA < valueB) return newSortAscending ? -1 : 1;
      if (valueA > valueB) return newSortAscending ? 1 : -1;
      return 0;
    };

    // Sort the table data based on the selected column and sort order
    const sortedTableData = [...tableData].sort(compareFunc);

    this.setState({
      tableData: sortedTableData,
      sortColumn: column,
      sortAscending: newSortAscending,
    });
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  toggleFullScreen = () => {
    this.setState((prevState) => ({
      isFullScreen: !prevState.isFullScreen,
    }));

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  handleClickReport = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
    });
    window.location.assign("/issuer/report_IssuerRecurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
    });
    window.location.assign("/issuer/collateral_IssuerRecurring");
  };
  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
    });
    window.location.assign("/issuer/loantape_IssuerRecurring");
  };
  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
    });
    window.location.assign("/issuer/file_IssuerRecurring");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/issuer/dashboard",
    });
  };

  dealPreviewMapFieldsRecurring = async () => {
    let x = moment(this.state.NextPaymentDate)
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;

    console.log("datata", data);
    this.setState({ screenloader: true });
    const APIResponse = await dealPreviewMapFieldsRecurring(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const tableData = APIResponse.data?.result || [];
      console.log("tableData", tableData);
      this.setState({
        MapDataKeys: tableData.length > 0 ? Object.keys(tableData[0]) : [],
        MapDataValues: APIResponse.data?.result?.map((item) =>
          Object.keys(item).map((key) => ({ value: item[key] }))
        ),
        screenloader: false,
        tableData: APIResponse.data?.result || ["no data"],
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("GeneralCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userid = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  handleDealChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState({
      DealName: newValue,
    });
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState({
      NextPaymentDate: newValue,
    });
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState({
      Version: newValue,
    });
  };

  DownloadExcel = async () => {
    this.setState({ getLoansLoader: true, formLoader1: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.file = `${this.state.DealName}-${month}-${year}.xlsx`;
    data.file2 = `${this.state.DealName}-${month}-${year}.xlsx`;

    console.log("data", data);
    const APIResponse = await DownloadExcel(data);

    console.log("DownloadExcel", APIResponse.data);
    if (APIResponse.status === 200) {
      const blob = new Blob([APIResponse.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", data.file);
      document.body.appendChild(link);
      link.click();

      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    } else {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    }
  };

  componentDidMount() {
    if (this.state.Version === "V1" || this.state.Version === "V2") {
      this.setState({ isDisable: this.state.isDisable });
      this.dealPreviewMapFieldsRecurring();
    } else {
      this.setState({ isDisable: false });
      this.dealPreviewMapFieldsRecurring();
    }
  }
  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (
          row[key] &&
          row[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData, // Reset the data to the original dataset
    });
  };

  filterData = (searchTerm) => {
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    return filteredData;
  };

  render() {
    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
    } = this.state;

    const filteredData = searchTerm ? this.filterData(searchTerm) : tableData;

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar activeComponent={"IssuerDashBoard"} />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                      <KeyboardBackspaceIcon
                        onClick={this.goBackToDashboard}
                        className="left-arrow-muis1 left-arrow-servicer"
                      ></KeyboardBackspaceIcon>
                      <h3 className="headerdashboard">
                        Trustee's Investor Report
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row row13">
                  <div className="input-generalContainer">
                    <div>
                      <label className="label">Deal Name</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state.DealName}
                        onChange={this.handleDealChange}
                      >
                        {this.state.getDashboardDeals.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-generalContainer">
                    <div>
                      <label className="label">Version</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state.Version}
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-generalContainer">
                    <div>
                      <label className="label">Payment Date</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "-35px" }}>
                  <div className="row row1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                      }}
                    >
                      <div>
                        <button
                          type="button"
                          onClick={() => this.handleClickReport()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-insights-active"
                              : "issuerDashboard-table-top-button-insights"
                          }
                        >
                          Report
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickCollateral()}
                          className={
                            this.state.activeInsights2 == true
                              ? "issuerDashboard-table-top-button-workbench-active"
                              : "issuerDashboard-table-top-button-workbench"
                          }
                        >
                          Collateral
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickLoanTape()}
                          className={
                            this.state.activeInsights3 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Loan Tape
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickFiles()}
                          className={
                            this.state.activeInsights4 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Files
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <div>
                        <div className="search-container active">
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={this.handleSearch}
                            placeholder="Search..."
                            className="search-input"
                          />
                          <span
                            className="search-icon"
                            onClick={this.handleSearchIconClick}
                          >
                            <SearchIcon />
                          </span>
                        </div>

                        {!isDataFound && <p>No data found.</p>}
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.DownloadExcel}
                      >
                        Download Excel
                        {this.state.formLoader1 === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </div>

                  {this.state.screenloader == true ? (
                    <LinearLoader></LinearLoader>
                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          overflow: "auto",
                          borderRadius: "10px",
                          border: "0.5px solid black",
                        }}
                      >
                        {/* <Spreadsheet
                        data={this.state?.MapDataValues}
                        title={"Servicer Details"}
                        DataEditor="false"
                        columnLabels={MapFieldKeys}
                        readOnly={true}
                      /> */}
                        <TableVirtuoso
                          style={{ height: 800 }}
                          data={filteredData}
                          components={TableComponents}
                          fixedHeaderContent={() => (
                            <TableRow>
                              <>
                                <TableCell
                                  style={{
                                    background: "white",
                                    width: "100px",
                                  }}
                                ></TableCell>
                                {MapDataKeys.map((column, index) => (
                                  <TableCell
                                    key={index}
                                    style={{
                                      background: "white",
                                      cursor: "pointer",
                                      width: `${column.length * 10}px`,
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() => this.handleSort(column)}
                                  >
                                    <div
                                      style={{ display: "flex", gap: "6px" }}
                                    >
                                      {column}
                                      {sortColumn === column && (
                                        <span>
                                          {sortAscending ? " ▲" : " ▼"}
                                        </span>
                                      )}
                                    </div>
                                  </TableCell>
                                ))}
                              </>
                            </TableRow>
                          )}
                          itemContent={(index, row) => (
                            <>
                              <TableCell
                                style={{ background: "white", width: "100px" }}
                              >
                                {index + 1}
                              </TableCell>
                              {MapDataKeys.map((column, columnIndex) => (
                                <TableCell
                                  key={columnIndex}
                                  style={{
                                    background: "white",
                                    width: `${column.length * 10}px`,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {row[column]}
                                </TableCell>
                              ))}
                            </>
                          )}
                        />
                      </div>
                      <div></div>
                    </React.Fragment>
                  )}
                  <>
                    <div className="btn_move_Needed">
                      <div className="btn_prevs" onClick={this.showPrev}>
                        Previous
                      </div>
                      <button className="btn_next" onClick={this.showNext}>
                        Next
                      </button>
                    </div>
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(LoanTapes_IssuerRecurring);
