// /* eslint-disable max-len */
// /* eslint-disable require-jsdoc */
// import React, { Component } from "react";
// import Button from "@material-ui/core/Button";
// import FormLoader from "../../../components/formLoader";
// import { withTheme } from "react-jsonschema-form";
// import { Theme as MuiTheme } from "rjsf-material-ui";
// import $ from "jquery";
// import LinkItem from "../../../components/linkItem";
// import { withSnackbar } from "notistack";
// import { authenticate, getAllUserRoles } from "../../../servies/services";
// // import Logo from '../../../images/wsfs-logo.jpg';
// // import Footer from '../../../components/footer/footer'
// import CryptoJS from "crypto-js";

// const Form = withTheme(MuiTheme);
// const schema = require("./schema.json");
// const uiSchema = {
//   Password: {
//     "ui:widget": "password",
//   },
// };

// class login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       schema: schema,
//       uiSchema: uiSchema,
//       formData: {},
//       loading: false,
//       OrgLoading: false,
//       role_list: [],
//       remainingAttempts: 3,
//     };
//   }

//   onChange = (e) => {
//     const formData = this.state.formData;
//     formData[e.target.name] = e.target.value;
//     this.setState({
//       formData,
//     });
//     console.log("formData", this.state.formData);
//   };

//   // Function for getting handling all events

//   onSubmit = async () => {
//     console.log("this.state.formData", this.state.formData);
//     if (
//       APIResponse.data.data.UserName == undefined ||
//       this.state.formData.Password == undefined 
//     ) {
//       const message = "Please select all fields";
//       this.props.enqueueSnackbar(message, {
//         variant: "error",
//         autoHideDuration: 5000,
//       });
//     } else {
//       // const DealType = $('#userroleid option:selected').attr('data-type');
//       // const OrgName2 = $('#userroleid option:selected').attr('value');
//       // console.log("DealType",DealType,OrgName2)
//       var data = {};
//       if (APIResponse.data.data.UserName == "Trustee") {
//         data = {
//           "UserName": APIResponse.data.data.UserName,
//           "Password": this.state.formData.Password,
//           "OrgName": "trustee",
//       }
//         this.authenticate(data);
//       } else if(APIResponse.data.data.UserName == "Investor"){
//         data = {
//           "UserName": APIResponse.data.data.UserName,
//           "Password": this.state.formData.Password,
//           "OrgName": "investor"
//         }
//         this.authenticate(data);
//       }else if(APIResponse.data.data.UserName == "Servicer"){
//         data = {
//           "UserName": APIResponse.data.data.UserName,
//           "Password": this.state.formData.Password,
//           "OrgName": "servicer"
//         }
//         this.authenticate(data);
//       }else if (APIResponse.data.data.UserName == "admin") {
//         data = {
//           "UserName": "admin",
//           "Password": this.state.formData.Password,
//           "OrgName": "trustee"
//         }
//         this.authenticate(data);        
//       }
//     }
//   };

//   authenticate = async (data) => {
//     this.setState({ loading: true });
//     const APIResponse = await authenticate(data);
//     // console.log("authenticate", APIResponse, APIResponse.status)
//     this.setState({ loading: false });
//     if (APIResponse !== null || APIResponse !== undefined) {
//       if (APIResponse.status == 204) {
//         this.setState({ remainingAttempts: this.state.remainingAttempts - 1 });

//         if (this.state.remainingAttempts <= 0) {
//           this.setState({ loading: true });

//           setTimeout(
//             () => this.setState({ loading: false, remainingAttempts: 3 }),
//             180000
//           );

//           const message =
//             "Your login attempts expired please wait for 3 minute";
//           this.props.enqueueSnackbar(message, {
//             variant: "error",
//             autoHideDuration: 5000,
//           });
//         } else {
//           const message = "Credentials Incorrect";
//           this.props.enqueueSnackbar(message, {
//             variant: "error",
//             autoHideDuration: 2000,
//           });
//         }
//       } else if (APIResponse.status !== 200) {
//         const message = "Something went wrong, please try again";
//         this.props.enqueueSnackbar(message, {
//           variant: "error",
//           autoHideDuration: 5000,
//         });
//       } else {
//         if (APIResponse.data.isSuccess == false) {
//           this.setState({ formLoader: false });
//           const message = "Credentials Incorrect";
//           this.props.enqueueSnackbar(message, {
//             variant: "error",
//             autoHideDuration: 2000,
//           });
//         } else {
//           if (APIResponse.data != "undefined") {
//             let OrgName = APIResponse.data.data.OrgName;
//             // sessionStorage.setItem("DealType", DealType);
//             sessionStorage.setItem("ChannelName","wldemochannel2")
//             sessionStorage.setItem("OrgName", OrgName);
//             sessionStorage.setItem("user_id", APIResponse.data.data.UserID);
//             sessionStorage.setItem(
//               "user_name",
//               APIResponse.data.data.UserName
//             );
//             sessionStorage.setItem("token", APIResponse.data.response.token);
//             // sessionStorage.setItem("ChannelName", DealType);
//             sessionStorage.setItem("emailid", APIResponse.data.data.EmailID);

//             var pass = this.state.formData.Password;
//             var key = CryptoJS.enc.Latin1.parse("ALtReKQqUH1VTh43vNomog==");
//             var iv = CryptoJS.enc.Latin1.parse("9988543267190345");
//             var encrypted = CryptoJS.AES.encrypt(pass, key, {
//               iv: iv,
//               mode: CryptoJS.mode.CBC,
//               padding: CryptoJS.pad.ZeroPadding,
//             });
//             console.log("encrypted: " + encrypted);
//             var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
//               iv: iv,
//               padding: CryptoJS.pad.ZeroPadding,
//             });
//             console.log("decrypted: " + decrypted.toString(CryptoJS.enc.Utf8));
//             sessionStorage.setItem("Pass", encrypted);

//             // let ChannelName = DealType;
//             // WSFS Stagin custer
//             let peer = "peer0-" + APIResponse.data.data.OrgName;
//             let p1 = "peer0-" + APIResponse.data.data.OrgName;

//             let peers = [p1];
//             sessionStorage.setItem("peer", peer);
//             sessionStorage.setItem("peers", JSON.stringify(peers));

//             if (APIResponse.data.data.UserName == "Trustee") {
//               this.setState({ formLoader: false });
//               const message = "Logged in successfully";
//               this.props.enqueueSnackbar(message, {
//                 variant: "info",
//                 autoHideDuration: 2000,
//               });
//               window.location.assign("/dashboard");
//             } else if (APIResponse.data.data.UserName == "Investor") {
//               this.setState({ formLoader: false });
//               const message = "Logged in successfully";
//               sessionStorage.setItem("userrole", "Investor");
//               this.props.enqueueSnackbar(message, {
//                 variant: "info",
//                 autoHideDuration: 2000,
//               });
//               window.location.assign("/investor/dashboard");
//             } else if (APIResponse.data.data.UserName == "Investor2") {
//               sessionStorage.setItem("userrole", "Investor2");
//               this.setState({ formLoader: false });
//               const message = "Logged in successfully";
//               this.props.enqueueSnackbar(message, {
//                 variant: "info",
//                 autoHideDuration: 2000,
//               });
//               window.location.assign("/investor/dashboard");      
//             }else if(APIResponse.data.data.UserName == "admin"){
//               this.setState({ formLoader: false });
//               const message = "Logged in successfully";
//               sessionStorage.setItem("userrole", "admin");
//               this.props.enqueueSnackbar(message, {
//                 variant: "info",
//                 autoHideDuration: 2000,
//               });              
//               window.location.assign("/admin/users/" + APIResponse.data.data.OrgName + "/Pending");
//             }    
//             else {
//               let token = APIResponse.data.response.token;
//               window.location.assign("/dashboard/servicer");
//               // this.GetAllDeals(DealType, token, peer, ChannelName, APIResponse.data.data.OrgName, APIResponse.data.data.FirstName)

//               // if (APIResponse.data.data.OrgName == "wsfstrustee") {
//               //   let token = APIResponse.data.response.token
//               //   this.GetAllDeals(DealType, token, peer, ChannelName)
//               // }
//               // else if (APIResponse.data.data.OrgName === 'investor') {
//               //   let token = APIResponse.data.response.token
//               //   this.GetAllDeals(DealType, token, peer, ChannelName)
//               // }
//               // else {
//               //   alert("Role not found - user type")
//               // }
//             }
//           }
//         }
//       }
//     }
//   };

//   async componentDidMount() {
//     sessionStorage.clear();
//     this.getAllUserRoles();

//     if (sessionStorage.getItem("OrgName") === null) {
//       this.getAllUserRoles();
//     } else {
//       const OrgName = JSON.parse(sessionStorage.getItem("OrgName"));
//       this.setState({ OrgLoading: true });
//       this.setState({ role_list: OrgName });
//     }
//   }

//   getAllUserRoles = async () => {
//     const APIResponse = await getAllUserRoles();
//     if (APIResponse != null) {
//       console.log("getAllUserRoles APIResponse.data", APIResponse.data);
//       this.setState({ OrgLoading: true, role_list: APIResponse.data });

//       sessionStorage.setItem("OrgName", JSON.stringify(APIResponse.data));
//     } else {
//       this.setState({ OrgLoading: true });
//     }
//   };

//   render() {
//     const x = this.state.formData;
//     console.log("XXX", x);
//     return (
//       <React.Fragment>
//         {this.state.loading === true ? <FormLoader></FormLoader> : ""}

//         <React.Fragment>
//           <div className="modalshiftcontent">
//             <div className="input-container">
//               <label className="label">Username</label>
//               <input
//                 id=""
//                 name="UserName"
//                 onChange={this.onChange}
//                 className="input"
//                 type="text"
//                 value={APIResponse.data.data.UserName}
//               />
//             </div>
//             <div className="input-container">
//               <label className="label">Password</label>
//               <input
//                 name="Password"
//                 type="password"
//                 onChange={this.onChange}
//                 className="input"
//                 value={this.state.formData.Password}
//               />
//             </div>
//             {/* <p> <label>Role</label>

//                   <div className="row">
//                     <label className="form-check-label radiorole">
//                       <input className="form-check-input radio-inline" type="radio" onChange={this.onChange} name="OrgName" id="gridRadios1" value="wsfstrustee" />
//                       Trustee</label>
//                     <label className="form-check-label radiorole">
//                       <input className="form-check-input radio-inline" type="radio" onChange={this.onChange} name="OrgName" id="gridRadios2" value="investor" />
//                       Issuer</label>

//                   </div>
//                   </p> */}

            
//             {/* <select name="OrgName" id="userroleid" onChange={this.onChange} className="form-control" >
//                     <option value={''} >{'Select Deal Name'}</option>
//                     {this.state.role_list.length != 0 ?
//                       Object.entries(this.state.role_list).map(([key, value]) => {
//                         return (
//                           <React.Fragment>

//                             <optgroup label={key} > {key} </optgroup>
//                             {value.map((item) => {
//                               return (
//                                 <option data-type={key} selected={this.state.formData.UserRoleName == item.UserRoleName} value={item.UserRoleName}>{item.UserRoleName}</option>
//                               );
//                             })
//                             }
//                           </React.Fragment>
//                         );
//                       })
//                       : ''}
//                   </select> */}

//             <p>
//               <Button
//                 className="col-md-12"
//                 onClick={this.onSubmit}
//                 variant="contained"
//                 size="large"
//                 color="primary"
//                 id="signinbutton"
//                 type="submit"
//                 disabled={this.state.loading === true ? true : false}
//               >
//                 Sign in
//               </Button>
//             </p>
//           </div>
//         </React.Fragment>

//         <div className="loginCheckbox">
//           <p className="lineDivider">
//             <span>New to  IntainADMIN?</span>
//           </p>
//           <LinkItem to={'/register'} variant="contained" className="loginBtn" title={'Create your Account'}> </LinkItem> 

//         {/* <div class="text-center Poweredby">Powered by <img id="wsfs_logo_first" style={{ width: "70px", height: "30px", marginTop:"-15px" }}
//                                 // src={"http://in-d.ai/wp-content/uploads/2020/11/WSFSLogo.png"
//                                           src={"https://wsfs.intainabs.com/logo.jpg"
//                                 }
//                             /></div> */}

//         </div>
//         {/* <Footer></Footer> */}
//       </React.Fragment>
//     );
//   }
// }

// export default withSnackbar(login);




/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormLoader from "../../../components/formLoader";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import $ from "jquery";
import LinkItem from "../../../components/linkItem";
import { withSnackbar } from "notistack";
import { authenticate2, getAllUserRoles } from "../../../servies/services";
// import Logo from '../../../images/wsfs-logo.jpg';
// import Footer from '../../../components/footer/footer'
import CryptoJS from "crypto-js";


const Form = withTheme(MuiTheme);
const schema = require("./schema.json");
const uiSchema = {
 Password: {
   "ui:widget": "password",
 },
};


class login extends Component {
 constructor(props) {
   super(props);
   this.state = {
     schema: schema,
     uiSchema: uiSchema,
     formData: {},
     loading: false,
     OrgLoading: false,
     role_list: [],
     remainingAttempts: 3,
     UserName: sessionStorage.getItem("UserName"),
     ChannelName:sessionStorage.getItem("ChannelName")
   };
 }


 onChange = (e) => {
   const formData = this.state.formData;
   formData[e.target.name] = e.target.value;
   this.setState({
     formData,
   });
   console.log("formData", this.state.formData);
 };


 // Function for getting handling all events


 onSubmit = async () => {
   console.log("this.state.formData", this.state.formData);
   if (!this.state.formData.EmailId || !this.state.formData.Password) {
     const message = "Please select all fields";
     this.props.enqueueSnackbar(message, {
       variant: "error",
       autoHideDuration: 5000,
     });
   }
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(this.state.formData.EmailId)) {
     const message = "Please enter a valid email address.";
     this.props.enqueueSnackbar(message, {
       variant: "error",
       autoHideDuration: 5000,
     });
     return;
   } else {
     // const DealType = $('#userroleid option:selected').attr('data-type');
     // const OrgName2 = $('#userroleid option:selected').attr('value');
     // console.log("DealType",DealType,OrgName2)
     var data = {};


     data = {
       EmailId: this.state.formData.EmailId,
       Password: this.state.formData.Password,
       OrgName: this.state.formData.OrgName,
     };
     this.authenticate2(data);
   }
 };


 // onSubmit = async () => {
 // console.log("this.state.formData", this.state.formData);
 // if (
 // this.state.formData.OrgName == undefined ||
 // this.state.formData.Password == undefined
 // ) {
 // const message = "Please select all fields";
 // this.props.enqueueSnackbar(message, {
 // variant: "error",
 // autoHideDuration: 5000,
 // });
 // } else {
 // // const DealType = $('#userroleid option:selected').attr('data-type');
 // // const OrgName2 = $('#userroleid option:selected').attr('value');
 // // console.log("DealType",DealType,OrgName2)
 // var data = {};
 // if (APIResponse.data.data.UserName == "Trustee") {
 // data = {
 // "UserName": APIResponse.data.data.UserName,
 // "Password": this.state.formData.Password,
 // "OrgName": "trustee",
 // }
 // this.authenticate(data);
 // } else if(APIResponse.data.data.UserName == "Investor"){
 // data = {
 // "UserName": APIResponse.data.data.UserName,
 // "Password": this.state.formData.Password,
 // "OrgName": "investor"
 // }
 // this.authenticate(data);
 // }else if(APIResponse.data.data.UserName == "Servicer"){
 // data = {
 // "UserName": APIResponse.data.data.UserName,
 // "Password": this.state.formData.Password,
 // "OrgName": "servicer"
 // }
 // this.authenticate(data);
 // }else if (APIResponse.data.data.UserName == "admin") {
 // data = {
 // "UserName": "admin",
 // "Password": this.state.formData.Password,
 // "OrgName": "trustee"
 // }
 // this.authenticate(data);
 // }
 // }
 // };


 authenticate2 = async (data) => {
   this.setState({ loading: true });
   const APIResponse = await authenticate2(data);
   // console.log("authenticate", APIResponse, APIResponse.status)
   this.setState({ loading: false });
   if (APIResponse !== null || APIResponse !== undefined) {
     if (APIResponse.status == 204) {
       this.setState({ remainingAttempts: this.state.remainingAttempts - 1 });


       if (this.state.remainingAttempts <= 0) {
         this.setState({ loading: true });


         setTimeout(
           () => this.setState({ loading: false, remainingAttempts: 3 }),
           180000
         );


         const message =
           "Your login attempts expired please wait for 3 minute";
         this.props.enqueueSnackbar(message, {
           variant: "error",
           autoHideDuration: 5000,
         });
       } else {
         const message = "Credentials Incorrect";
         this.props.enqueueSnackbar(message, {
           variant: "error",
           autoHideDuration: 2000,
         });
       }
     } else if (APIResponse.status !== 200) {
       const message = "Something went wrong, please try again";
       this.props.enqueueSnackbar(message, {
         variant: "error",
         autoHideDuration: 5000,
       });
     } else {
       if (APIResponse.data.isSuccess == false) {
         this.setState({ formLoader: false });
         const message = "Credentials Incorrect";
         this.props.enqueueSnackbar(message, {
           variant: "error",
           autoHideDuration: 2000,
         });
       } else {
         if (APIResponse.data != "undefined") {
           let OrgName = APIResponse.data.data.OrgName;
           // sessionStorage.setItem("DealType", DealType);
           sessionStorage.setItem(
             "ChannelName",
             APIResponse.data.data.ChannelName
           );
           sessionStorage.setItem("OrgName", OrgName);
           sessionStorage.setItem("user_id", APIResponse.data.data.UserID);
           sessionStorage.setItem("user_name", APIResponse.data.data.UserName);
           sessionStorage.setItem("token", APIResponse.data.response.token);
           // sessionStorage.setItem("ChannelName", DealType);
           sessionStorage.setItem("emailid", APIResponse.data.data.EmailID);

           var pass = this.state.formData.Password;
           var key = CryptoJS.enc.Latin1.parse("ALtReKQqUH1VTh43vNomog==");
           var iv = CryptoJS.enc.Latin1.parse("9988543267190345");
           var encrypted = CryptoJS.AES.encrypt(pass, key, {
             iv: iv,
             mode: CryptoJS.mode.CBC,
             padding: CryptoJS.pad.ZeroPadding,
           });
           console.log("encrypted: " + encrypted);
           var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
             iv: iv,
             padding: CryptoJS.pad.ZeroPadding,
           });
           console.log("decrypted: " + decrypted.toString(CryptoJS.enc.Utf8));
           sessionStorage.setItem("Pass", encrypted);

           // let ChannelName = DealType;
           // WSFS Stagin custer
           const determinePeer = () => {
             let peer;
             switch (process.env.react_app_base_url) {
               case "https://preprod.iaedge.intainabs.com/":
                 peer = "peer0-" + APIResponse.data.data.OrgName;
                 break;
                 case "https://ia-uat.iaedge.intainabs.com/":
                 peer = "peer0-" + APIResponse.data.data.OrgName;
                 break;
               case "https://intainadmin.intainabs.com/":
                 peer = "peer0." + APIResponse.data.data.OrgName + "-net";
                 break;
               default:
                 peer = "peer0-" + APIResponse.data.data.OrgName;
             }
             return peer;
           };

           let peer = determinePeer();

           let p1 = determinePeer();

           let peers = [p1];
           sessionStorage.setItem("peer", peer);
           sessionStorage.setItem("peers", JSON.stringify(peers));

           if (
             this.state.formData.OrgName == "trustee" &&
             APIResponse.data.data.UserName == "admin"
           ) {
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign(
               "/admin/users/" + APIResponse.data.data.OrgName + "/Pending"
             );
           } else if (
             this.state.formData.OrgName == "trustee" &&
             APIResponse.data.data.UserName !== "admin"
           ) {
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign("/dashboard");
           } else if (
             this.state.formData.OrgName == "investor" &&
             APIResponse.data.data.UserName == "admin"
           ) {
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             sessionStorage.setItem("userrole", "investor");
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign(
               "/admin/users/" + APIResponse.data.data.OrgName + "/Pending"
             );
           } else if (
             this.state.formData.OrgName == "investor" &&
             APIResponse.data.data.UserName !== "admin"
           ) {
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             sessionStorage.setItem("userrole", "investor");
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign("investor/dashboard");
           } else if (
             this.state.formData.OrgName == "investor2" &&
             APIResponse.data.data.UserName == "admin"
           ) {
             sessionStorage.setItem("userrole", "investor2");
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign(
               "/admin/users/" + APIResponse.data.data.OrgName + "/Pending"
             );
           } else if (
             this.state.formData.OrgName == "investor2" &&
             APIResponse.data.data.UserName !== "admin"
           ) {
             sessionStorage.setItem("userrole", "investor2");
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign("/investor/dashboard");
           } else if (this.state.formData.OrgName == "admin") {
             this.setState({ formLoader: false });
             const message = "Logged in successfully";
             sessionStorage.setItem("userrole", "admin");
             this.props.enqueueSnackbar(message, {
               variant: "info",
               autoHideDuration: 2000,
             });
             window.location.assign(
               "/admin/users/" + APIResponse.data.data.OrgName + "/Pending"
             );
           } else if(this.state.formData.OrgName == "servicer" &&
           APIResponse.data.data.UserName == "admin"){
            sessionStorage.setItem("userrole", "servicer");
            this.setState({ formLoader: false });
            const message = "Logged in successfully";
            this.props.enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 2000,
            });
            window.location.assign(
              "/admin/users/" + APIResponse.data.data.OrgName + "/Pending"
            );
          }else {
             let token = APIResponse.data.response.token;
             window.location.assign("/dashboard/servicer");
             // this.GetAllDeals(DealType, token, peer, ChannelName, APIResponse.data.data.OrgName, APIResponse.data.data.FirstName)

             // if (APIResponse.data.data.OrgName == "wsfstrustee") {
             // let token = APIResponse.data.response.token
             // this.GetAllDeals(DealType, token, peer, ChannelName)
             // }
             // else if (APIResponse.data.data.OrgName === 'investor') {
             // let token = APIResponse.data.response.token
             // this.GetAllDeals(DealType, token, peer, ChannelName)
             // }
             // else {
             // alert("Role not found - user type")
             // }
           }
         }
       }
     }
   }
 };


 async componentDidMount() {
   sessionStorage.clear();
   this.getAllUserRoles();


   if (sessionStorage.getItem("OrgName") === null) {
     this.getAllUserRoles();
   } else {
     const OrgName = JSON.parse(sessionStorage.getItem("OrgName"));
     this.setState({ OrgLoading: true });
     this.setState({ role_list: OrgName });
   }
 }


 getAllUserRoles = async () => {
   const APIResponse = await getAllUserRoles();
   if (APIResponse != null) {
     console.log("getAllUserRoles APIResponse.data", APIResponse.data);
     this.setState({ OrgLoading: true, role_list: APIResponse.data });


     sessionStorage.setItem("OrgName", JSON.stringify(APIResponse.data));
   } else {
     this.setState({ OrgLoading: true });
   }
 };


 render() {
   const x = this.state.formData;
   console.log("XXX", x);
   return (
     <React.Fragment>
       {this.state.loading === true ? <FormLoader></FormLoader> : ""}


       <React.Fragment>
         <div className="modalshiftcontent">
           <div className="input-container">
             <label className="label">Email ID</label>
             <input
               id=""
               name="EmailId"
               onChange={this.onChange}
               className="input"
               type="text"
               value={this.state.formData.EmailId}
               autoComplete={false}ConsolidatedAggregateSummaryToBC
             />
           </div>
           <div className="input-container">
             <label className="label">Password</label>
             <input
               name="Password"
               type="password"
               onChange={this.onChange}
               className="input"
               value={this.state.formData.Password}
               autoComplete={false}
             />
           </div>
           <div className="input-container">
             <label className="label">OrgName</label>
             {/* <select
               name="OrgName"
               value={this.state.formData.OrgName}
               onChange={this.onChange}
               className="input"
             >
               <option value="">Select OrgName</option>
               <option value="trustee">Trustee</option>
               <option value="investor">Investor</option>
               <option value="servicer">Servicer</option>
             </select> */}
              <select
                    name="OrgName" // Change the name to "OrgName"
                    id="userroleid"
                    onChange={this.onChange}
                    className="form-control"
                    required
                    value={this.state.formData.OrgName} // Set the selected value to formData.OrgName
                  >
                    <option value={""}>{"Select OrgName"}</option>
                    {this.state.role_list.length !== 0
                      ? this.state.role_list.map((key, value) => {
                          return key.UserRoleName.includes("inv") ||
                            key.UserRoleName.includes("issuer") ||
                            key.UserRoleName.includes("trustee") ||
                            key.UserRoleName.includes("servicer") ? (
                            <option
                              data-type={key.UserRoleName}
                              data-userroleid={key.UserRoleID}
                              key={key.UserRoleID}
                              value={key.UserRoleName}
                            >
                              {key.UserRoleName.charAt(0).toUpperCase() +
                                key.UserRoleName.slice(1)}
                            </option>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </select>
           </div>


           {/* <p> <label>Role</label>


<div className="row">
<label className="form-check-label radiorole">
<input className="form-check-input radio-inline" type="radio" onChange={this.onChange} name="OrgName" id="gridRadios1" value="wsfstrustee" />
Trustee</label>
<label className="form-check-label radiorole">
<input className="form-check-input radio-inline" type="radio" onChange={this.onChange} name="OrgName" id="gridRadios2" value="investor" />
Issuer</label>


</div>
</p> */}


           {/* <select name="OrgName" id="userroleid" onChange={this.onChange} className="form-control" >
<option value={''} >{'Select Deal Name'}</option>
{this.state.role_list.length != 0 ?
Object.entries(this.state.role_list).map(([key, value]) => {
return (
<React.Fragment>


<optgroup label={key} > {key} </optgroup>
{value.map((item) => {
return (
<option data-type={key} selected={this.state.formData.UserRoleName == item.UserRoleName} value={item.UserRoleName}>{item.UserRoleName}</option>
);
})
}
</React.Fragment>
);
})
: ''}
</select> */}


           <p>
             <Button
               className="col-md-12"
               onClick={this.onSubmit}
               variant="contained"
               size="large"
               color="primary"
               id="signinbutton"
               type="submit"
               disabled={this.state.loading === true ? true : false}
             >
               Sign in
             </Button>
           </p>
         </div>
       </React.Fragment>


       <div className="loginCheckbox">
         <p className="lineDivider">
           <span>New to IntainADMIN?</span>
         </p>
         <LinkItem
           to={"/register"}
           variant="contained"
           className="loginBtn"
           title={"Create your Account"}
         >
           {" "}
         </LinkItem>


         {/* <div class="text-center Poweredby">Powered by <img id="wsfs_logo_first" style={{ width: "70px", height: "30px", marginTop:"-15px" }}
// src={"http://in-d.ai/wp-content/uploads/2020/11/WSFSLogo.png"
src={"https://wsfs.intainabs.com/logo.jpg"
}
/></div> */}
       </div>
       {/* <Footer></Footer> */}
     </React.Fragment>
   );
 }
}


export default withSnackbar(login);




