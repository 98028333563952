import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../../components/sidebar/sidebar";
import Header from "../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { servicerGetalldeals } from "../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../components/loader";
import NumberComp2 from "../../../../components/NumberComp2";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import * as moment from "moment";
import { data } from "jquery";
import { Servicer } from "../../../../components/sidebar/menu";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

class ServicerMainDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      loading: false,
      getLoansLoader: false,
      screenloader: false,
      NextPaymentDate: "",
      LastPaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      isDateSelected:null,
      showSearchBox: false,
      searchText: "",
    };
  }
  AddDeal = () => {
    this.props.history.push({
      pathname: "/admin/general",
      state:{checkDeal:true}
    });
  };
  Recurring = (value, tableMeta) => {
    console.log(tableMeta.rowData);
    sessionStorage.setItem("servicerdealname", value);
    sessionStorage.setItem("selectservicerdate", this.state.NextPaymentDate === "" ? tableMeta.rowData[4] : this.state.NextPaymentDate);
    sessionStorage.setItem("servicerdashboardname", tableMeta.rowData[2]);
    this.props.history.push({
      pathname: "/admin/summarize",
      state: {ViewServicer:"Yes"}
    });
  };
  UploadLoanTape = (value, tableMeta) => {
    sessionStorage.setItem("selectservicerdate", this.state.NextPaymentDate);
    this.props.history.push({
      pathname: "/admin/uploadloantape",
      state:{ServicerPaymentDate : this.state.NextPaymentDate === "" ? tableMeta.rowData[4] : this.state.NextPaymentDate,ServicerDealName:value, ServicerDashboardName:tableMeta.rowData[2]}
      // { detailsView: this.state.detailsDateViewDetails === undefined ? sessionStorage.getItem('selectdate') : this.state.detailsDateViewDetails, dealname: value, servicer: tableMeta.rowData[1]},
    });
  };
  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state:{checkDeal:false}
    });
  };
  servicerGetalldeals = async () => {
    var data = {};
    data.ServicerName = "Servicer";
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await servicerGetalldeals(data);

    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        loading: false,
      });

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onchange = (e) => {
    this.setState({ searchText: e.target.value, searchType: true });
    // console.log("search: e.target.value", e.target.value);
  };
  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
        />
        {/* {this.state.searchTextDashboard.length !== 0 ? <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchTextDashboard: '' })} /> : ''} */}
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.removeItem('selectservicerdate');
    sessionStorage.removeItem('servicerdealname');
    sessionStorage.removeItem('servicerdashboardname');
    sessionStorage.removeItem("dealname");
    this.servicerGetalldeals();
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });
  render() {
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      onRowHover: this.onRowHover,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: TrainRounded,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Deal Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };
    
    const getAllDates = (date,Index,tableMeta) => {
      console.log("date", date,Index,tableMeta);
      const changeDateFormat = Index === tableMeta.rowIndex ? moment(date).format("MM/DD/YYYY").toString() : null;
      this.setState({dateNeeded:Index})
      console.log("changeDateFormat",changeDateFormat);
      return changeDateFormat;
    };

    const getIndex = (index) => {
      console.log("index",index);
      this.setState({isDateSelected:index})
      return index;
    }

    const columns = [
      {
        name: "Deal Name",
        label: "Deal Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Asset Class",
        label: "Asset Class",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Servicer",
        label: "Servicer",
        options: {
          filter: true,
          sort: true         
        },
      },
      {
        name: "Last Payment Date",
        label: "Last Payment Date",
        options: {
          filter: true,
          sort: true,
          display:false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "20px",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("value", value);
            return (
              <React.Fragment>
                <div className="align-actions1">
                  {value !== "" ? (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        margin="normal"
                        id="date1"
                        value={
                          this.state.LastPaymentDate === ""
                            ? value
                            : this.state.LastPaymentDate
                        }
                        onChange={(date) =>
                          this.setState({
                            LastPaymentDate: moment(date)
                              .format("MM/DD/YYYY")
                              .toString(),
                          })
                        }
                        keyboard
                        placeholder="MM/DD/YYYY"
                        format={"MM/DD/YYYY"}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                        variant="filled"
                        helperText={""}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Next Payment Date",
        label: "Payment Date",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="align-actions1" onClick={()=>getIndex(tableMeta.rowIndex)}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      margin="normal"
                      id="date1"
                      value={
                        this.state.NextPaymentDate === ""
                          ? value
                          : tableMeta.rowIndex === this.state.isDateSelected ? this.state.NextPaymentDate : value
                      }
                      onChange={(date) =>
                        this.setState({
                          NextPaymentDate:getAllDates(date,tableMeta.rowIndex,tableMeta),
                        })
                      }
                      keyboard
                      placeholder="MM/DD/YYYY"
                      format={"MM/DD/YYYY"}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                      autoOk={true}
                      clearable
                      variant="filled"
                      helperText={""}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Loan Tape Process Status",
        label: "Processed",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign:"center",
                paddingTop: "5px",
                paddingRight: "4rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                paddingRight: "4rem",                
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },      
      {
        name: "Last Modified",
        label: "Last Modified",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign:"center",
                paddingTop: "5px",
                paddingRight: "4rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                paddingRight: "4rem",
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },
      {
        name: "Deal Name",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft:"1rem",
                paddingRight: "4rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("tabe meta", tableMeta.rowData[5]);
            return (
              <React.Fragment>
                <div className="align-actions">
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
                  {/* <button className="popupdashboard" onClick={() => this.Servicer()}>View Servicers</button> */}
                 
                      <button
                      className="popupdashboard"
                      onClick={() => this.UploadLoanTape(value, tableMeta)}
                    >
                      Process Loan Tape
                    </button>
                
                 
                 {tableMeta.rowData[5] == "Yes" ? (
                    <button
                      className="popupdashboard"
                      onClick={() => this.Recurring(value, tableMeta)}
                    >
                      View Aggregate Summary
                    </button> 
                 ):(
                  <button
                  className="login-sign_up-links button-disabled"
                  onClick={() => this.Recurring(value, tableMeta)}
                  disabled
                >
                  View Aggregate Summary
                </button> 
                 )       }        
        
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },      
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"ServicerMainDashboard"} />
        <div className="content">
          <div className="header">
            <Header></Header>
          </div>
          <div className="page-content">
            <div className="row row1">
              <div className="investor-heading-container">
                {this.state.showSearchBox == true ? (
                  this.searchBar()
                ) : (
                  <h1 className="headerdashboard">Dashboard</h1>
                )}
              </div>

              <div>
                <div className="dashboard-top-right-container1">
                  <div className="search-bar-outer-container uw-search-button-container">
                    {this.state.showSearchBox == false && (
                      <button
                        className="search-mui-icons"
                        style={{"position":"relative","top":"0.5rem"}}
                        type="button"
                        onClick={this.onClickSearchButton}
                      >
                        <SearchOutlinedIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <React.Fragment>
              <div className="workbench-table-container">
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    // title={'Dashboard'}
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(ServicerMainDashboard);
