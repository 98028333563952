import axios from "axios";
import { createBrowserHistory } from "history";


const token = sessionStorage.getItem("token");
sessionStorage.setItem("peer_insurer", "peer0.insurer-net");
const peer = sessionStorage.getItem("peer");
const peer_insurer = "peer0.insurer-net";
const peers = sessionStorage.getItem("peers");
const factor_peer = "peer0.factor-net";
const userid = sessionStorage.getItem("user_id");
const LimaPeer = "peer1.trustee.trurep.testing.emulya.com";
const role = "trustee";
const saludaPeer = sessionStorage.getItem("peer");
const ChannelName = sessionStorage.getItem("ChannelName");


export const history = createBrowserHistory();


export const API = axios.create({
 baseURL: process.env.react_app_base_url + "backendapiwl/",
});


API.interceptors.response.use(
 (response) => {
   console.log("response", response);
   if (response.data.token == -1) {
     alert("Token expired, please login again!");
     // window.location.href = "/";
   } else {
     return response;
   }
 },
 (error) => {
   console.log("error", JSON.stringify(error));
   if (!error.response) {
     console.log("Interceptor - Server is not running");
     // window.location.href = "/server-down";
   } else if (error.response.status === 401) {
     console.log(
       "Interceptor - 401 - Unauthorized: Token Invalid, please login again"
     );
     // alert("Token Missing!")
     sessionStorage.clear();
     window.location.assign("/");
   } else if (error.response.status === 400) {
     console.log("Interceptor - 400" + error.response.data.messages);
     alert("Missing Arguments!");
     return null;
   } else if (error.response.status === 503) {
     console.log("Interceptor - 503" + error.response.data.messages);
     alert("503 service unavailable");
     return null;
   } else if (error.response.status === 504) {
     console.log("Interceptor - 504" + error.response.data.messages);
     alert("504 Gateway Timeout");
     return null;
   } else if (error.response.status === 422) {
     console.log("Interceptor - 422" + error.response.data.messages);
     return error;
   } else if (error.response.status === 404) {
     console.log("Interceptor - 404" + error.response.data.messages);
     return error;
   } else if (error.response.status === 500) {
     console.log("Interceptor - 500" + error.response.data.messages);
     alert("Internal Server Error");
     return error;
   } else {
     return Promise.reject(error);
   }
 }
);


export const authenticate = async (data) => {
 let headers = {
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 console.log("authenticate data", data);
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/authenticate",
   data,
   { headers }
 );
 return response;
};


export const authenticate2 = async (data) => {
 let headers = {
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 console.log("authenticate data", data);
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/authenticate2",
   data,
   { headers }
 );
 return response;
};


export const registerUser = async (UserRole, data) => {
 let headers = {
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };


 let response = null;
 if (UserRole == "trustee") {
   response = await API.post(
     process.env.react_app_base_url + "backendapiwl/registerUser",
     data,
     { headers }
   );
   return response;
 } else if (UserRole == "servicer") {
   response = await API.post(
     process.env.react_app_base_url + "backendapiwl/registerUser",
     data,
     { headers }
   );
   return response;
 } else if (UserRole == "investor") {
   response = await API.post(
     process.env.react_app_base_url + "backendapiwl/registerUser",
     data,
     { headers }
   );
   return response;
 } else if (UserRole == "investor2") {
   response = await API.post(
     process.env.react_app_base_url + "backendapiwl/registerUser",
     data,
     { headers }
   );
   return response;
 }
};


// export const getAllUserRoles = async () => {
//  const response = await API.get(process.env.react_app_base_url + 'backendapiwl/getAllUserRoles',{headers : {"platform_name" : '' }})
//  return response;
// };


export const getAllUserRoles = async () => {
 let headers = {
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };


 const response = await API.get(
   process.env.react_app_base_url + "backendapiwl/getAllUserRoles",
   { headers }
 );
 return response;
};


export const getuserByOrgname = async (OrgName) => {
 console.log("OrgName", OrgName);


 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };


 if (OrgName == "trustee") {
   const response = await API.get(
     process.env.react_app_base_url +
       "backendapiwl/getUserByOrgName?OrgName=" +
       OrgName +
       "&peer=" +
       peer,
     { headers }
   );
   return response;
 } else if (OrgName == "servicer") {
   const response = await API.get(
     process.env.react_app_base_url +
       "backendapiwl/getUserByOrgName/?OrgName=" +
       OrgName +
       "&peer=" +
       peer,
     { headers }
   );
   return response;
 } else if (OrgName == "investor") {
   const response = await API.get(
     process.env.react_app_base_url +
       "backendapiwl/getUserByOrgName/?OrgName=" +
       OrgName +
       "&peer=" +
       peer,
     { headers }
   );
   return response;
 } else if (OrgName == "investor2") {
   const response = await API.get(
     process.env.react_app_base_url +
       "backendapiwl/getUserByOrgName/?OrgName=" +
       OrgName +
       "&peer=" +
       peer,
     { headers }
   );
   return response;
 }
};


export const approveUser = async (OrgName, data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 // channelname : ChannelName,
 };


 const apiUrl = process.env.react_app_base_url + "backendapiwl/approveUser";
 const response = await API.post(apiUrl, data, { headers });
 console.log(OrgName, response);


 return response;
};


export const updateApprovedUser = async (data) => {
 let headers = {
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };


 const apiUrl =
   process.env.react_app_base_url +
   "backendapiwl/updateApprovedUserProfileDetails";
 const response = await API.post(apiUrl, data, { headers });
 console.log(response);


 return response;
};


const determinePlatformName = () => {
 switch (process.env.react_app_base_url) {
   case "https://preprod.iaedge.intainabs.com/":
     return "WilmingTon";
   case "https://intainadmin.intainabs.com/":
     return "IARedesign";
     case "https://ia-uat.iaedge.intainabs.com/":
      return "IA_UAT"
   default:
     return "WSFS";
 }
};


// STD Fields
export const getdefinition = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
   channelname : ChannelName,
   };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/getdefinition?AssetType=" +
     data.AssetType +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};
export const deletedefinition = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const apiUrl =
   process.env.react_app_base_url + "backendapiwl/trustee/deletedefinition";
 const response = await API.post(apiUrl, data, { headers });


 return response;
};
export const updatedefinition = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const apiUrl =
   process.env.react_app_base_url + "backendapiwl/updatedefinition";
 const response = await API.post(apiUrl, data, { headers });
 return response;
};


//Intain Admin Apis deal creation


export const getalldeals = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/getalldeals?peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const getDealDetailsByPaymentDate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/getDealDetailsByPaymentDate",
   data,
   { headers }
 );
 return response;
};


export const collateralDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
   channelname : ChannelName,
   };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/viewcollateralReport?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealPreviewMapFieldsRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/loanTape",
   data,
   { headers }
 );
 return response;
};


export const PublishReport = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/publish",
   data,
   { headers }
 );
 return response;
};


export const servicerGetalldeals = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/servicer/getalldeals?ServicerName=" +
     data.ServicerName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealServicerViewDetails = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/dealservicerlist?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealCreationAddSave = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/savetableexp",
   data,
   { headers }
 );
 return response;
};


export const getPDFLogoData = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/viewpdflogo?ChannelName=" +
     data.ChannelName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealCreationTable = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/viewtableexp?DealName=" +
     data.DealName +
     "&TableName=" +
     data.TableName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealCreation_BC_Status = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/bcstatus?DealName=" +
     data.DealName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const dealCreationCreateUpdate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/createupdate",
   data,
   { headers }
 );
 return response;
};


export const dealCreationDelete = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/deletetableexp",
   data,
   { headers }
 );
 return response;
};
export const dealCreationEdit = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/edittableexp",
   data,
   { headers }
 );
 return response;
};


export const dealCreationSaveForNow = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/savefornow",
   data,
   { headers }
 );
 return response;
};


export const PostPDFLogoData = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/uploadpdflogo",
   data,
   { headers }
 );
 return response;
};


export const dealCreationAutoSave = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/autosave",
   data,
   { headers }
 );
 return response;
};


export const ViewAccountTable = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/viewaccounttable",
   data,
   { headers }
 );
 return response;
};


export const ViewBorrowingTable = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/viewborrowingbasetable",
   data,
   { headers }
 );
 return response;
};


export const savedealservicerdate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/savedealservicerdate",
   data,
   { headers }
 );
 return response;
};
export const generatedealid = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/generatedealid?DealName=" +
     data.DealName +
     "&ClosingDate=" +
     data.ClosingDate +
     "&TableName=" +
     data.TableName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const checkDealName = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/dealcheck?DealName=" +
     data.DealName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


//Intain Admin Apis Loan processing


export const SaveLoanProcessDate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/saveloanprocessdate",
   data,
   { headers }
 );
 return response;
};


export const ViewAggregateSummary = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/viewaggregatesummary?peer=" +
     data.peer +
     "&DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&ServicerName=" +
     data.ServicerName,
   { headers }
 );
 return response;
};


export const ConsolidatedAggregateSummaryToDB = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/consolidatedaggregatesummarytodb",
   data,
   { headers }
 );
 return response;
};


export const ConsolidatedAggregateSummaryToBC = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/consolidatedaggregatesummarytobc",
   data,
   { headers }
 );
 return response;
};


export const dealUploadLoanTape = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/UploadLoanTape",
   data,
   { headers }
 );
 return response;
};


export const dealPreviewLoanTape = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/PreviewLoanTape1?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&ServicerName=" +
     data.ServicerName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const StdfieldsQuery = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/StdfieldsQuery?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&ServicerName=" +
     data.ServicerName +
     "&peer=" +
     data.peer +
     "&AssetType=" +
     data.AssetType,
   { headers }
 );
 return response;
};


export const getMapping = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/getMapping?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&ServicerName=" +
     data.ServicerName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const saveMapping = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/saveMapping",
   data,
   { headers }
 );
 return response;
};


export const dealPreviewMapFields = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/PreviewMappedFields?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&ServicerName=" +
     data.ServicerName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const Summarize = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/summarize",
   data,
   { headers }
 );
 return response;
};


export const SaveAggregateSummaryToBC = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/saveaggregatesummarytobc",
   data,
   { headers }
 );
 return response;
};


//-------------------------------------------------------------- recurring --------------------------------------------------//


export const generalOnPageReload = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring",
   data,
   { headers }
 );
 return response;
};


export const getDealDetailsByDealName = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/investor/getDealDetailsByDealName?DealName=" +
     data.DealName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};
// export const generalDealRecurring = async (data) => {
//  const response = await API.post(process.env.react_app_base_url + 'backendapiwl/trustee/recurring/general',data,{ headers: {"authorization" : `Bearer ${token}`} })
//  return response;
// };


export const DealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&TableName=" +
     data.TableName +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const ModifiedDateRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/modifieddate?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&peer=" +
     data.peer +
     "&OrgName=" +
     data.OrgName,
   { headers }
 );
 return response;
};
export const InvestorReportData = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/invreport?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&NextPaymentDate=" +
     data.NextPaymentDate +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const getDealDetailsByInvestorPaymentDate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/investor/getDealDetailsByPaymentDate",
   data,
   { headers }
 );
 return response;
};
export const tranchesGetDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/tranchetxn?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&TableName=" +
     data.TableName +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const getIndexDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/tranches?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&TableName=" +
     data.TableName +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const editTranchestransactionDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/edittranchetransaction",
   data,
   { headers }
 );
 return response;
};


export const deleteTranchestransactionDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/deletetranchetransaction",
   data,
   { headers }
 );
 return response;
};


export const addtransactionTranchesRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/addtranchetransaction",
   data,
   { headers }
 );
 return response;
};


export const CalculatePayments = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/calculatepayments",
   data,
   { headers }
 );
 return response;
};


export const CalculateCollateralPayments = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/collateralReport",
   data,
   { headers }
 );
 return response;
};


export const CalculateConsolidatedPayments = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/consolidatedReport",
   data,
   { headers }
 );
 return response;
};


export const CalculatePrePayments = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/prepayment",
   data,
   { headers }
 );
 return response;
};


export const ViewConsolidatedReport = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/viewconsolidatedReport?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const getAllVersionsByPaymentDate = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/getAllVersionsByPaymentDate?DealName=" +
     data.DealName +
     "&selectedDate=" +
     data.selectedDate +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const FilesAddDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/addDealDocument",
   data,
   { headers }
 );
 return response;
};
export const FilesRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/viewDealDocument?DealName=" +
     data.DealName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const DownloadRowClick = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/downloadDealDocument?documentID=" +
     data.documentID +
     "&peer=" +
     data.peer,
   {
     headers,
     responseType: "arraybuffer", // Add this line to request binary data
   }
 );
 return response;
};


export const DownloadExcel = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
 };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/downloadconsolidatedloantape?file=" +
     data.file +
     "&file2=" +
     data.file2,
   {
     headers,
     responseType: "arraybuffer", // Add this line to request binary data
   }
 );
 return response;
};


export const tranchesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/tranches",
   data,
   { headers }
 );
 return response;
};


export const feesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/fees",
   data,
   { headers }
 );
 return response;
};


export const expensesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/expenses",
   data,
   { headers }
 );
 return response;
};


export const editexpensesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/editexpenses",
   data,
   { headers }
 );
 return response;
};

export const editmanualinputRecurring = async (data) => {
  let headers = {
    authorization: `Bearer ${token}`,
    platformname: determinePlatformName(),
  channelname : ChannelName,
    };
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapiwsfs/trustee/recurring/editManualInputs",
    data,
    { headers }
  );
  return response;
};


export const testsDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/tests",
   data,
   { headers }
 );
 return response;
};


export const variablesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/variables",
   data,
   { headers }
 );
 return response;
};


export const accountsDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/trustee/recurring/accounts",
   data,
   { headers }
 );
 return response;
};


export const addtransactionDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/addtransaction",
   data,
   { headers }
 );
 return response;
};


export const deletetransactionDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/deletetransaction",
   data,
   { headers }
 );
 return response;
};


export const edittransactionDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/edittransaction",
   data,
   { headers }
 );
 return response;
};


export const paymentrulesDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/paymentrules",
   data,
   { headers }
 );
 return response;
};


export const calculatepaymentsDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/calculatepayments",
   data,
   { headers }
 );
 return response;
};


export const generatereportDealRecurring = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/generatereport",
   data,
   { headers }
 );
 return response;
};


export const exportAsPDF = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url + "backendapiwl/invreportpdf",
   data,
   { headers }
 );
 return response;
};


//-------------------------------------------------------------- Investor Dashboard --------------------------------------------------//


export const investorGetalldeals = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/investor/getalldeals?EmailId=" +
     data.Email +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const months = [
 {
   value: "1",
   label: "January",
 },
 {
   value: "2",
   label: "February",
 },
 {
   value: "3",
   label: "March",
 },
 {
   value: "4",
   label: "April",
 },
 {
   value: "5",
   label: "May",
 },
 {
   value: "6",
   label: "June",
 },
 {
   value: "7",
   label: "July",
 },
 {
   value: "8",
   label: "August",
 },
 {
   value: "9",
   label: "September",
 },
 {
   value: "10",
   label: "October",
 },
 {
   value: "11",
   label: "November",
 },
 {
   value: "12",
   label: "December",
 },
];


export function generateYears() {
 let min = 2010;
 console.log("min", min);
 let max = new Date().getFullYear();
 console.log("max", max);
 let years = [];
 for (var i = min; i <= max; i++) {
   console.log("asdasd", i);
   years.push({
     value: i.toString(),
     label: i.toString(),
   });
 }
 let reversarray = years.reverse();
 return reversarray;
}


 //---------------------------\\
export const CalculateESMAPayments = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.post(
   process.env.react_app_base_url +
     "backendapiwl/trustee/recurring/EsmaReport",
   data,
   { headers }
 );
 return response;
};


export const ViewEsmaReport = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/recurring/viewesmaReport?DealName=" +
     data.DealName +
     "&Month=" +
     data.Month +
     "&Year=" +
     data.Year +
     "&Version=" +
     data.Version +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};


export const getAllPaymentDatesByDealName = async (data) => {
 let headers = {
   authorization: `Bearer ${token}`,
   platformname: determinePlatformName(),
 channelname : ChannelName,
   };
 const response = await API.get(
   process.env.react_app_base_url +
     "backendapiwl/getAllPaymentDatesandVersionsByDealName?DealName=" +
     data.DealName +
     "&peer=" +
     data.peer,
   { headers }
 );
 return response;
};

