import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CircularProgress from "@material-ui/core/CircularProgress";
import { customStylesauto } from "../../../../../components/customscripts/customscript";
import CloseIcon from "@material-ui/icons/Close";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";
import LinearLoader from "../../../../../components/loader/LinearLoader";

import {
  ModifiedDateRecurring,
  CalculatePayments,
  CalculatePrePayments,
  collateralDealRecurring,
  CalculateConsolidatedPayments,
  getDealDetailsByDealName,
  getAllVersionsByPaymentDate,
} from "../../../../../servies/services";
import * as moment from "moment";
import ReactModal from "react-modal";
import NumberComp2 from "../../../../../components/NumberComp2";

class Collateral_InvRecurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      tableData: [],
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      OrgName: sessionStorage.getItem("OrgName"),
      TableName: "PaymentRules",
      peers: JSON.parse(sessionStorage.getItem("peers")),
      Version: sessionStorage.getItem("version"),
      peer: sessionStorage.getItem("peer"),
      isVersionChange: JSON.parse(sessionStorage.getItem("isVersionChange")),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      formData: {
        Name: "",
        LastModified: "",
      },
      formData1: {
        Name: "",
        LastModified: "",
      },
      deleteRowVal: {
        Name: "",
      },
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      rowsSelected: null,
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      tables: [],
    };
  }
  showPrev = () => {
    window.location.assign("/investor/report_InvRecurring");
  };
  showNext = () => {
    window.location.assign("/investor/loantape_InvRecurring");
  };
  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkRecurring: true },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.Collateral(this.state.DealName)}>
          PDF
        </button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Collateral(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          EXCEL
        </button>
      </Popover>
    );
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
  // onOpenModal1 = () => {
  // this.setState({ open2: true });
  // };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/investor/dashboard",
    });
  };
  handleClickReport = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
    });
    window.location.assign("/investor/report_InvRecurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
    });
    window.location.assign("/investor/collateral_InvRecurring");
  };
  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
    });
    window.location.assign("/investor/loantape_InvRecurring");
  };
  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
    });
    window.location.assign("/investor/file_InvRecurring");
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        checkRecurringCollateral: true,
        checkRecurringLoanTape: false,
        checkRecurringConsolidated: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("PaymentCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userid = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  ModifiedDateRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.OrgName = this.state.OrgName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await ModifiedDateRecurring(data);

    console.log("ModifiedDateRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        loading: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      // variant: "info",
      // autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  collateralDealRecurring = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      screenloader: true,
    });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = sessionStorage.getItem("version");
    data.peer = this.state.peer;

    console.log("datatatata", data);
    const APIResponse = await collateralDealRecurring(data);
    console.log("collateralDealRecurringggg", APIResponse);
    console.log(
      "collateralDealRecurring",
      APIResponse.data.data.DealContactInformation
    );
    // this.setState({

    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data.data,
        loading: false,
        screenloader: false,
        openModalPayment: false,
      });

      for (const key in APIResponse.data.data) {
        if (Array.isArray(APIResponse.data.data[key])) {
          const table = this.createTable(APIResponse.data.data[key], key);
          this.setState((prevState) => ({
            tables: prevState.tables.concat(table),
          }));
        }
      }
      console.log("tables", this.state.tableData.DealContactInformation);
      // console.log(
      //   "tables",
      //   this.state.tableData.DealContactInformation["dealid"]
      // );
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleDealChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        DealName: newValue,
        screenloader: true,
      },
      async () => {
        sessionStorage.setItem("dealname", newValue);
        await this.getDealDetailsByDealName();
        // await this.collateralDealRecurring();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version },
            async () => {
              sessionStorage.setItem("version", "WIP");
              await this.collateralDealRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            await this.collateralDealRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              {
                isVersionChange: false,
                publishVersion: Version,
              },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.collateralDealRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getDealDetailsByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getDealDetailsByDealName(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem("version", APIResponse.data.Versions[0]);
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Versions)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        NextPaymentDate: newValue,
        screenloader: true,
        // openModalPayment: true
      },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        sessionStorage.setItem("nextpaymentdate", newValue);
        await this.getAllVersionsByPaymentDate();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version },
            async () => {
              sessionStorage.setItem("version", "WIP");
              await this.collateralDealRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            this.collateralDealRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isVersionChange: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.collateralDealRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        Version: newValue,
        screenloader: true,
      },
      async () => {
        console.log("pppppp", this.state.Version);
        sessionStorage.setItem("version", newValue);

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.collateralDealRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: sessionStorage.getItem("version"),
            });
            this.collateralDealRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.collateralDealRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  componentDidMount() {
    // var component = window.location.pathname;
    // sessionStorage.setItem("component", component);
    // this.ModifiedDateRecurring();

    this.setState({ isVersionChange: false });
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isVersionChange: false }, async () => {
        sessionStorage.setItem("version", "WIP");
        await this.collateralDealRecurring();
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isVersionChange: this.state.isVersionChange });
        this.collateralDealRecurring();
      } else {
        this.setState({ isVersionChange: false, Version: "WIP" }, async () => {
          await this.collateralDealRecurring();
        });
      }
    }
  }

  createTable = (dataArray, key) => {
    console.log("dataArray", dataArray);
    console.log("key", key);

    const tableRows = dataArray.map((item, rowIndex) => (
      <tr key={rowIndex}>
        {Object.keys(item).map((header, index) => {
          const value = item[header];
          const isNumberOrPercentage =
            typeof value === "string" &&
            (value.endsWith("%") || /^-?\d+(\.\d+)?$/.test(value));
          const isStringWithAlphabets =
            typeof value === "string" && /[a-zA-Z]/.test(value);
          const isMaturityDate = header === "Maturity Date"; // Check if the header is "Maturity Date"
          const textAlign = isNumberOrPercentage
            ? "right"
            : isStringWithAlphabets
            ? "left"
            : isMaturityDate
            ? "right"
            : isMaturityDate
            ? "left"
            : "right";

          return (
            <td
              key={index}
              style={{
                textAlign,
                width: "10%",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {isMaturityDate ? value : <NumberComp2 value={value} />}
            </td>
          );
        })}
      </tr>
    ));

    console.log("tableRows", tableRows);

    const tableHeader = Object.keys(dataArray[0]).map((header, index) => (
      <th
        key={index}
        style={{
          textAlign: "center",
          width: "10%",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        {header}
      </th>
    ));

    return (
      <div key={key} className="wrapper-pdf-container">
        <h4 className="sectiontitle">{key}</h4>
        <table className="table table-bordered">
          <thead className="wrapper-pdf-container table.table.table-bordered thead">
            <tr>{tableHeader}</tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    );
  };

  render() {
    const { tableData } = this.state;
    // const firstObject =tableData;
    // console.log("firstObject",firstObject)
    console.log("firstObject", tableData.DealContactInformation);
    const a = Object.keys(tableData);
    console.log("a", a);
    console.log("tables", this.state.tableData?.DealContactInformation?.dealid);

    // const firstObjectKey = Object.keys(this.state.data)[0];
    // console.log("firstObject", firstObject);
    // const firstObjectContent = (
    // <div className="first-object-content">
    // {console.log("1313130", this.state.data[0])}
    // {console.log(`${firstObject}:`)}

    // <h4>{firstObjectKey}:</h4>
    // <ul>
    // {Object.entries(firstObject).map(([key, value]) => (
    // <div key={key}>
    // <strong>{key}:</strong> {value}
    // </div>
    // ))}
    // </ul>
    // </div>
    // );
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"InvestorDashBoard"} />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1.5rem",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                        <KeyboardBackspaceIcon
                          onClick={this.goBackToDashboard}
                          className="left-arrow-muis1 left-arrow-servicer"
                        ></KeyboardBackspaceIcon>
                        <h3 className="headerdashboard">
                          Trustee's Investor Report
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row row13">
                    <div className="input-generalContainer">
                      <div>
                        <label className="label">Deal Name</label>
                        <select
                          className="input-select-general-new1"
                          value={this.state.DealName}
                          onChange={this.handleDealChange}
                        >
                          {this.state.getDashboardDeals.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="input-generalContainer">
                      <div>
                        <label className="label">Payment Date</label>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="input-generalContainer">
                      <div>
                        <label className="label">Version</label>
                        <select
                          className="input-select-general-new1"
                          value={this.state.Version}
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "-35px" }}>
                    <div className="row row1">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "-10px",
                        }}
                      >
                        <div>
                          <button
                            type="button"
                            onClick={() => this.handleClickReport()}
                            className={
                              this.state.activeInsights1 == true
                                ? "issuerDashboard-table-top-button-insights-active"
                                : "issuerDashboard-table-top-button-insights"
                            }
                          >
                            Report
                          </button>

                          <button
                            type="button"
                            onClick={() => this.handleClickCollateral()}
                            className={
                              this.state.activeInsights2 == true
                                ? "issuerDashboard-table-top-button-workbench-active"
                                : "issuerDashboard-table-top-button-workbench"
                            }
                          >
                            Collateral
                          </button>

                          <button
                            type="button"
                            onClick={() => this.handleClickLoanTape()}
                            className={
                              this.state.activeInsights3 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            Loan Tape
                          </button>
                          <button
                            type="button"
                            onClick={() => this.handleClickFiles()}
                            className={
                              this.state.activeInsights4 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            Files
                          </button>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <React.Fragment>
                      <div className="css-collateral">
                        {/* {firstObjectContent} */}

                        <div>{this.state.tables}</div>
                      </div>
                      <>
                        <div className="btn_move_Needed">
                          <div className="btn_prevs" onClick={this.showPrev}>
                            Previous
                          </div>

                          <button className="btn_next" onClick={this.showNext}>
                            Next
                          </button>
                        </div>
                      </>
                    </React.Fragment>

                    <>
                      <ReactModal
                        isOpen={this.state.open3}
                        onRequestClose={this.onCloseModal2}
                        contentLabel="Minimal Modal Example"
                        style={customStylesauto}
                      >
                        <React.Fragment>
                          <div className="modalPopup">
                            <h2>Edit Report</h2>
                            <button
                              type="button"
                              className="closePopup"
                              style={{ minWidth: "30px" }}
                              onClick={this.onCloseModal2}
                            >
                              <CloseIcon></CloseIcon>{" "}
                            </button>

                            {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                            <div className="modalshiftcontent">
                              <form
                                className="form-container"
                                onSubmit={this.EditSubmit}
                              >
                                <div className="input-container">
                                  <label className="label"> Name</label>
                                  <input
                                    required
                                    placeholder="Type here"
                                    className="input"
                                    type="text"
                                    onChange={(e) => {
                                      this.setState({
                                        formData1: {
                                          ...this.state.formData1,
                                          Name: e.target.value,
                                        },
                                      });
                                    }}
                                    value={this.state.formData1.Name}
                                  />
                                </div>

                                <div className="input-container">
                                  <label className="label">Last Modified</label>
                                  <input
                                    placeholder="Type here"
                                    className="input"
                                    type="text"
                                    onChange={(e) => {
                                      this.setState({
                                        formData1: {
                                          ...this.state.formData1,
                                          LastModified: e.target.value,
                                        },
                                      });
                                    }}
                                    value={this.state.formData1.LastModified}
                                  />
                                </div>

                                <div className="modalsubmit">
                                  <div className="submitbuttonbg">
                                    <div className="row">
                                      <div className="row justify-content-end1">
                                        <button
                                          type="button"
                                          className="popupbutton22"
                                          onClick={this.onCloseModal2}
                                        >
                                          {" "}
                                          Cancel{" "}
                                        </button>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                        >
                                          Save
                                          {this.state.formLoader === true ? (
                                            <CircularProgress
                                              size="22px"
                                              color="primary"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </React.Fragment>
                      </ReactModal>

                      <div id="modal1">
                        <ReactModal
                          isOpen={this.state.open4}
                          onRequestClose={this.onCloseModal3}
                          contentLabel="Minimal Modal Example"
                          style={customStylesautosmallmodal1}
                        >
                          <React.Fragment>
                            <div className="modalPopup">
                              <h3 className="popupheading">
                                Are you sure, you want to Delete this Fee "
                                {this.state.deleteRowVal.Name}"?
                              </h3>

                              {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                              <div className="modalshiftcontent">
                                <div className="modalsubmit">
                                  <div className="submitbuttonbg">
                                    <div className="row">
                                      <div className="row justify-content-end11">
                                        <button
                                          type="button"
                                          className="popupbutton1"
                                          onClick={this.onCloseModal3}
                                        >
                                          No
                                        </button>

                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          onClick={this.dealCreationDelete}
                                        >
                                          Yes, Delete it
                                          {this.state.formLoader === true ? (
                                            <CircularProgress
                                              size="25px"
                                              color="primary"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.getLoansLoader === false ? (
                                  ""
                                ) : (
                                  <FormLoader></FormLoader>
                                )}
                                {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                              </div>
                            </div>
                          </React.Fragment>
                        </ReactModal>
                      </div>
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Collateral_InvRecurring);
